import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, forkJoin, map, of, throwError } from 'rxjs';
import { CurrencyService, EmployeeService, InvoiceTypeService, UnitService } from '../core/services';
import { NotificationService } from '../core/components/notification/notification.service';
import { LoadingService } from '../core/components/loading/loading.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerResolver implements Resolve<any> {
  constructor(
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    
    private currencyService: CurrencyService,
    private employeeService: EmployeeService,
    private invoiceTypeService: InvoiceTypeService,
    private router: Router,
  ) { }

  resolve(): Observable<any> {
    this.loadingService.show()
    const getAllCurrencies$ = this.currencyService.getAllCurrencies()
    const getAllEmployees$ = this.employeeService.findAllEmployees()
    const getInvoiceTypes$ = this.invoiceTypeService.getAllInvoiceTypes()

    return forkJoin([getAllCurrencies$, getAllEmployees$, getInvoiceTypes$]).pipe(
      map(([currencyResponse, employeeResponse, invoiceResponse]) => {
        this.loadingService.hide()
        return {
          currencies: currencyResponse.data,
          employees: employeeResponse.data,
          invoices: invoiceResponse.data,
        };
        
      }),
      catchError((error) => {
        this.loadingService.hide()
        console.error('Error occurred:', error);
        this.notificationService.Warning(
          'The connection with the server seems to be broken. Please check the internet connection. Try logging into the system again. Contact the administrator.',
          'Connection error!'
        );

        this.router.navigate(['/connection-lost']);

        // Returning an observable to prevent the route from being resolved with an empty object
        return of([]);
        // return error

      })
    );
  }
}
