import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, forkJoin, map, of, throwError } from 'rxjs';
import { DepartmentService, InventoryGroupService } from '../core/services';
import { NotificationService } from '../core/components/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class ServerHealthResolver implements Resolve<any> {
  constructor(
    private inventoryGroupService: InventoryGroupService,
    private notificationService: NotificationService,
    private departmentService: DepartmentService,
    private router: Router,
  ) { }

  resolve(): Observable<any> {
    return this.inventoryGroupService.getAllInventoryGroups()
      .pipe(
        catchError((error) => {
          console.error('Error fetching data:', error);
          this.notificationService.Warning("The connection with the server seems to be broken. Please check the internet connection. Try logging into the system again. Contact the administrator.", "Connection error!")

          this.router.navigate(['/connection-lost']);
          return of([]);
          // return error
        }),
        // map((data) => {
        //   // Redirect to dashboard if data is fetched successfully
        //   this.router.navigate(['/dashboard']);
        //   return data; // Pass the data through
        // })
      )
  }
}
