import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, forkJoin, map, of, throwError } from 'rxjs';
import { CurrencyService, DepartmentService, InventoryGroupService, InventoryTypeService, UnitService } from '../core/services';
import { NotificationService } from '../core/components/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class SupplierResolver implements Resolve<any> {
  constructor(
    private notificationService: NotificationService,
    private inventoryGroupService: InventoryGroupService,
    private departmentService: DepartmentService,
    private inventoryTypeService: InventoryTypeService,
    private unitService: UnitService,

    private currencyService: CurrencyService,
    private router: Router,
  ) { }

  resolve(): Observable<any> {
    const getAllCurrencies$ = this.currencyService.getAllCurrencies()

    return forkJoin([getAllCurrencies$]).pipe(
      map(([currencyResponse]) => {
        return {
          currencies: currencyResponse.data,
        };
      }),
      catchError((error) => {
        console.error('Error occurred:', error);
        this.notificationService.Warning(
          'The connection with the server seems to be broken. Please check the internet connection. Try logging into the system again. Contact the administrator.',
          'Connection error!'
        );

        this.router.navigate(['/connection-lost']);

        // Returning an observable to prevent the route from being resolved with an empty object
        return of([]);
        // return error

      })
    );
  }
}
