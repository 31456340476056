import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, forkJoin, map, of, throwError } from 'rxjs';
import { DepartmentService, InventoryGroupService, InventoryTypeService, UnitService } from '../core/services';
import { NotificationService } from '../core/components/notification/notification.service';
import { LoadingService } from '../core/components/loading/loading.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryResolver implements Resolve<any> {
  constructor(
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    private inventoryGroupService: InventoryGroupService,
    private departmentService: DepartmentService,
    private inventoryTypeService: InventoryTypeService,
    private unitService: UnitService,
    private router: Router,
  ) { }

  resolve(): Observable<any> {
    this.loadingService.show()
    const getAllInventoryGroups$ = this.inventoryGroupService.getAllInventoryGroups()
    const getAllInventoryTypes$ = this.inventoryTypeService.getAllInventoryTypes()
    const getAllDepartments$ = this.departmentService.getAllDepartments()
    const getAllUnits$ = this.unitService.getAllUnits()

    return forkJoin([getAllInventoryGroups$, getAllInventoryTypes$, getAllDepartments$, getAllUnits$]).pipe(
      map(([inventoryGroupResponse, inventoryTypeResponse, departmentResponse, unitResponse]) => {
        this.loadingService.hide()
        return {
          inventoryGroups: inventoryGroupResponse.data,
          inventoryTypes: inventoryTypeResponse.data,
          departments: departmentResponse.data,
          units: unitResponse.data,
        };
      }),
      catchError((error) => {
        this.loadingService.hide()
        console.error('Error occurred:', error);
        this.notificationService.Warning(
          'The connection with the server seems to be broken. Please check the internet connection. Try logging into the system again. Contact the administrator.',
          'Connection error!'
        );

        this.router.navigate(['/connection-lost']);

        // Returning an observable to prevent the route from being resolved with an empty object
        return of([]);
        // return error

      })
    );
  }
}
