import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, forkJoin, map, of, throwError } from 'rxjs';
import { CompanyProfileService, CurrencyService, DepartmentService, EmployeeService, InventoryGroupService, InventoryTypeService, SupplierService, TaxService, UnitService } from '../core/services';
import { NotificationService } from '../core/components/notification/notification.service';
import { LoadingService } from '../core/components/loading/loading.service';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderResolver implements Resolve<any> {
  constructor(
    private notificationService: NotificationService,
    private loadingService: LoadingService,
    
    private currencyService: CurrencyService,
    private departmentService: DepartmentService,
    private companyProfileService: CompanyProfileService,

    private supplierService: SupplierService,
    private taxService: TaxService,
    private inventoryTypeService: InventoryTypeService,
    private router: Router,
  ) { }

  resolve(): Observable<any> {
    this.loadingService.show()
    const getAllCurrencies$ = this.currencyService.getAllCurrencies()
    const getAllSuppliers$ = this.supplierService.getAllSuppliers()
    const getAllDepartments$ = this.departmentService.getAllDepartments()
    const getCompanyProfiles$ = this.companyProfileService.getCompanyProfiles()
    const getAllTaxeGroups$ = this.taxService.getAllTaxeGroups()
    const getAllInventoryTYpes$ = this.inventoryTypeService.getAllInventoryTypes()


    return forkJoin([getAllCurrencies$, getAllSuppliers$, getAllDepartments$, getCompanyProfiles$, getAllTaxeGroups$, getAllInventoryTYpes$]).pipe(
      map(([currencyResponse, supplierResponse, departmentResponse, CompanyProfileResponse, taxGroupResponse, inventoryResponse]) => {
        this.loadingService.hide()
        return {
          currencies: currencyResponse.data,
          suppliers: supplierResponse.data,
          departments: departmentResponse.data,
          companyProfiles: CompanyProfileResponse.data,
          taxGroups: taxGroupResponse.data,
          inventoriyTypes: inventoryResponse.data,
        };

      }),
      catchError((error) => {
        this.loadingService.hide()
        console.error('Error occurred:', error);
        this.notificationService.Warning(
          'The connection with the server seems to be broken. Please check the internet connection. Try logging into the system again. Contact the administrator.',
          'Connection error!'
        );

        this.router.navigate(['/connection-lost']);

        // Returning an observable to prevent the route from being resolved with an empty object
        return of([]);
        // return error

      })
    );
  }
}
